import './contact.css'
import Contacts from '../../sections/contacts/Contacts'

export const Contact = () => {
  return (
    <>
    <Contacts/>
    </>
  )
}

export default Contact
import './services.css'
import Header from '../../sections/header/Header'
import Body from '../../sections/body/Body'

const Services = () => {
  return (
    <>
    <Header/>
    <Body/>
    </>
  )
}

export default Services
import './home.css'
import Mainheader from '../../sections/mainheader/MainHeader'
import Services from '../../sections/services/Services'

export const Home = () => {
  return (
    <>
    <Mainheader/>
    <Services/>
    </>
  )
}

export default Home